<template>
    <div class="container">
        <div class="header_common_box">
            <v-header :params="{ title, leftIcon: true}"></v-header>
        </div>
        <div class="article">
            <h2>兑换服务协议</h2>
            <p>
                请认真仔细阅读以下兑换协议内容:
            </p>
            <p>
                <br/>
            </p>
            <p>
                1.<span style="color: rgb(255, 0, 0);"><span style="background-color: rgb(255, 255, 255);">需实名认证，才能兑换收益</span>。</span>为了保证您的收益受到保护，兑换收益前需要实名认证,实名认证审核通过后，下次兑换不需要再次实名(审核需要时间，需要1-2个工作日，若有问题请联系管理员，联系方式在下
            </p>
            <p>
                2.<span style="color: rgb(255, 0, 0);">最低100起兑，最高20000， 每月一兑，从提交兑换起，7个工作日内到账。</span>由于兑换人数众多，为了确保平台兑换业务正常运作,最低100起兑，最高20000，每月只能兑换-次。从提交兑换收益时间算起，预计7个工作日后到账，节假日顺延，若未到账可联系我们客服，联系方式在下方最底部
            </p>
            <p>
                3.<span style="color: rgb(255, 0, 0);">严格依照《中华人民共和国个人所得税法》，按照相关要求需要代缴个税。</span>遵守国家相应法律法规，严格按照规定，平台代缴个人所得税，请参考《中华人民共和国个人所得税法实施条例》、《劳 务报酬所得的计税方法》具体详细税款运算，见下文。
            </p>
            <p>
                4.<span style="color: rgb(255, 0, 0);">累计兑换收益超过800，之后的收益需要签约合同才能继续兑换。</span>按照国家工商和税局要求，超过相应比例的兑换收益，将需要提供合同，具体操作，请微信联系客服，联系方式见下方
            </p>
            <p>
                5.<span style="color: rgb(255, 0, 0);">一个账号-一个实名认证，若有多个账号将全部封号，并停止兑换服务。</span>平台郑重声明:一个用户不允许使用多个小号，更不允许使用多个小号使用兑换服务功能，一经查处,将全部封停所有账号，并且停止兑换收益功能，请知悉!
            </p>
            <p>
                6.<span style="color: rgb(255, 0, 0);">禁言/封号用户，不允许兑换。</span>您已阅读并同意平台相关协议，违反平台管理规范，或相关协议导致被禁言或者被封号的用户，平台有权不提供兑换功能
            </p>
            <p>
                7.<span style="color: rgb(255, 0, 0);">收益也可以充太阳，充值后不允许再兑换回来。</span>收益是可以充太阳的，但是充了太阳后是不允许兑换回来，请先确定自己需求和意愿，这个操作是不可逆的。
            </p>
            <p>
                8.<span style="color: rgb(255, 0, 0);">个人取得劳务报酬缴纳多少个人所得税?</span>《中华人民共和国个人所得税法》
            </p>
            <p>
                http://www.chinatax.gov.cn/chinatax/n810346/n81 0825/c101434/c21603671/content.html
            </p>
            <p>
                <span style="color: rgb(255, 0, 0);">2019年新个税法规定个人取得劳务报酬所得，和工资薪金所得，稿酬所得和特许权使用费所得合并为综合所得，需在应取得所得的次年三月一日至六月三十日内自行办理汇算清缴，详见下方链接。</span>
            </p>
            <p>
                《国家税务总局关于个人所得税自行纳税申报有关问题的公告》
            </p>
            <p>
                http://www.chinatax.gov.cn/chinatax/n810346/n810825/c101 434/c21 603680/content.html
            </p>
            <p>
                <br/>
            </p>
            <p>
                问1、当兑换的收益小于等于800时(&lt;=800)，如何运算?
            </p>
            <p>
                答:税款免费。按税法规定，一月提款小于等于800时，免费提取，不需要缴纳税款
            </p>
            <p>
                <br/>
            </p>
            <p>
                问2、当兑换的收益大于800，小于4000时(没有等于)，如何运算?
            </p>
            <p>
                答:税款= (兑换收益-800) * 20%，假如我要兑换1000，(1000 - 800) * 20%=40，即:1000-40(税款)=960(即最终所得)
            </p>
            <p>
                <br/>
            </p>
            <p>
                问3、当兑换的收益大于等于4000，小于等于20000时，如何运算?
            </p>
            <p>
                答:税款= (兑换收益* 80%) * 20%，假如我要兑换20000，(20000*80%)20% = 3200，即: 20000- 3200 (税款)= 16800 (即最终所得)
            </p>
            <p>
                <br/>
            </p>
            <p>
                <span style="color: rgb(255, 0, 0);">注:上面运算依照目前的《国家税法》作为参考，一切计算结果皆以最新的《国家税法》为准。</span>
            </p>
            <p>
                <br/>
            </p>
            <p>
                <span @click="goPage('contact')" style="text-decoration: underline;"><span style="text-decoration: underline; color: rgb(0, 112, 192);">点击联系我们</span></span>
            </p>
            <p>
                <br/>
            </p>
        </div>
    </div>
</template>
<script>
import vHeader from "@/components/header.vue";

export default {
    data(){
        return{
            title:this.$route.meta.title
        }
    },
    components:{
        vHeader
    },
    created(){
    }
}
</script>
<style lang="less" scoped>
    .container{
        .article{
            padding: 0 10px;
            font-size: 16px;
            h2{
                text-align: center;
            }
        }
    }
</style>