var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container"},[_c('div',{staticClass:"header_common_box"},[_c('v-header',{attrs:{"params":{ title: _vm.title, leftIcon: true}}})],1),_c('div',{staticClass:"article"},[_c('h2',[_vm._v("兑换服务协议")]),_c('p',[_vm._v(" 请认真仔细阅读以下兑换协议内容: ")]),_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6),_vm._m(7),_vm._m(8),_c('p',[_vm._v(" http://www.chinatax.gov.cn/chinatax/n810346/n81 0825/c101434/c21603671/content.html ")]),_vm._m(9),_c('p',[_vm._v(" 《国家税务总局关于个人所得税自行纳税申报有关问题的公告》 ")]),_c('p',[_vm._v(" http://www.chinatax.gov.cn/chinatax/n810346/n810825/c101 434/c21 603680/content.html ")]),_vm._m(10),_c('p',[_vm._v(" 问1、当兑换的收益小于等于800时(<=800)，如何运算? ")]),_c('p',[_vm._v(" 答:税款免费。按税法规定，一月提款小于等于800时，免费提取，不需要缴纳税款 ")]),_vm._m(11),_c('p',[_vm._v(" 问2、当兑换的收益大于800，小于4000时(没有等于)，如何运算? ")]),_c('p',[_vm._v(" 答:税款= (兑换收益-800) * 20%，假如我要兑换1000，(1000 - 800) * 20%=40，即:1000-40(税款)=960(即最终所得) ")]),_vm._m(12),_c('p',[_vm._v(" 问3、当兑换的收益大于等于4000，小于等于20000时，如何运算? ")]),_c('p',[_vm._v(" 答:税款= (兑换收益* 80%) * 20%，假如我要兑换20000，(20000*80%)20% = 3200，即: 20000- 3200 (税款)= 16800 (即最终所得) ")]),_vm._m(13),_vm._m(14),_vm._m(15),_c('p',[_c('span',{staticStyle:{"text-decoration":"underline"},on:{"click":function($event){return _vm.goPage('contact')}}},[_c('span',{staticStyle:{"text-decoration":"underline","color":"rgb(0, 112, 192)"}},[_vm._v("点击联系我们")])])]),_vm._m(16)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('br')])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v(" 1."),_c('span',{staticStyle:{"color":"rgb(255, 0, 0)"}},[_c('span',{staticStyle:{"background-color":"rgb(255, 255, 255)"}},[_vm._v("需实名认证，才能兑换收益")]),_vm._v("。")]),_vm._v("为了保证您的收益受到保护，兑换收益前需要实名认证,实名认证审核通过后，下次兑换不需要再次实名(审核需要时间，需要1-2个工作日，若有问题请联系管理员，联系方式在下 ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v(" 2."),_c('span',{staticStyle:{"color":"rgb(255, 0, 0)"}},[_vm._v("最低100起兑，最高20000， 每月一兑，从提交兑换起，7个工作日内到账。")]),_vm._v("由于兑换人数众多，为了确保平台兑换业务正常运作,最低100起兑，最高20000，每月只能兑换-次。从提交兑换收益时间算起，预计7个工作日后到账，节假日顺延，若未到账可联系我们客服，联系方式在下方最底部 ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v(" 3."),_c('span',{staticStyle:{"color":"rgb(255, 0, 0)"}},[_vm._v("严格依照《中华人民共和国个人所得税法》，按照相关要求需要代缴个税。")]),_vm._v("遵守国家相应法律法规，严格按照规定，平台代缴个人所得税，请参考《中华人民共和国个人所得税法实施条例》、《劳 务报酬所得的计税方法》具体详细税款运算，见下文。 ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v(" 4."),_c('span',{staticStyle:{"color":"rgb(255, 0, 0)"}},[_vm._v("累计兑换收益超过800，之后的收益需要签约合同才能继续兑换。")]),_vm._v("按照国家工商和税局要求，超过相应比例的兑换收益，将需要提供合同，具体操作，请微信联系客服，联系方式见下方 ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v(" 5."),_c('span',{staticStyle:{"color":"rgb(255, 0, 0)"}},[_vm._v("一个账号-一个实名认证，若有多个账号将全部封号，并停止兑换服务。")]),_vm._v("平台郑重声明:一个用户不允许使用多个小号，更不允许使用多个小号使用兑换服务功能，一经查处,将全部封停所有账号，并且停止兑换收益功能，请知悉! ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v(" 6."),_c('span',{staticStyle:{"color":"rgb(255, 0, 0)"}},[_vm._v("禁言/封号用户，不允许兑换。")]),_vm._v("您已阅读并同意平台相关协议，违反平台管理规范，或相关协议导致被禁言或者被封号的用户，平台有权不提供兑换功能 ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v(" 7."),_c('span',{staticStyle:{"color":"rgb(255, 0, 0)"}},[_vm._v("收益也可以充太阳，充值后不允许再兑换回来。")]),_vm._v("收益是可以充太阳的，但是充了太阳后是不允许兑换回来，请先确定自己需求和意愿，这个操作是不可逆的。 ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v(" 8."),_c('span',{staticStyle:{"color":"rgb(255, 0, 0)"}},[_vm._v("个人取得劳务报酬缴纳多少个人所得税?")]),_vm._v("《中华人民共和国个人所得税法》 ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('span',{staticStyle:{"color":"rgb(255, 0, 0)"}},[_vm._v("2019年新个税法规定个人取得劳务报酬所得，和工资薪金所得，稿酬所得和特许权使用费所得合并为综合所得，需在应取得所得的次年三月一日至六月三十日内自行办理汇算清缴，详见下方链接。")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('br')])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('br')])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('br')])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('br')])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('span',{staticStyle:{"color":"rgb(255, 0, 0)"}},[_vm._v("注:上面运算依照目前的《国家税法》作为参考，一切计算结果皆以最新的《国家税法》为准。")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('br')])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('br')])
}]

export { render, staticRenderFns }